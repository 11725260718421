<template>
  <v-navigation-drawer
    class="pt-16 mt-2 position-fixed"
    v-model="drawer"
    app>
    <v-list rounded
            dense>
      <v-list-item-group
        active-class="light-blue--text text--accent-4">
        <v-list-item
          :to="{name:'Home'}">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!--    Général -->
    <v-subheader>
      {{ _.toUpper($vuetify.lang.t('$vuetify.general.name')) }}
    </v-subheader>
    <v-list rounded
            dense>
      <v-list-item-group
        active-class="light-blue--text text--accent-4">
        <v-list-item
          :to="{name:'GeneralClients'}">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $vuetify.lang.t('$vuetify.general.clients.name') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{name:'GeneralUsers'}">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Utilisateurs</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file-table-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Demandes</v-list-item-title>
        </v-list-item>
        <v-list-group
          no-action
          prepend-icon="mdi-basket"
        >
          <template v-slot:activator>
            <v-list-item-title>Produits</v-list-item-title>
          </template>
          <v-list-item :to="{ name:'GeneralProducts' }">
            <v-list-item-title>Produits</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name:'GeneralProductTaxes' }">
            <v-list-item-title>Taxes</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name:'GeneralProductFamilies' }">
            <v-list-item-title>Familles</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>

    <!--  Site  -->
    <v-subheader>
      SITES
    </v-subheader>
    <v-list rounded
            dense>
      <v-list-item-group
        active-class="light-blue--text text--accent-4">
        <v-list-group v-for="site in sites" :key="site.id">
          <template v-slot:activator>
            <v-list-item-title>{{ site.value }}</v-list-item-title>
          </template>

          <v-list-item :to="{ name:'SiteProductList', params: { id: site.id } }">
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.site.products.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name:'SiteCatalogueList', params: { id: site.id } }">
            <v-list-item-icon>
              <v-icon>mdi-shopping</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.site.catalogs.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-star-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.site.solutions.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-sale</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.site.promos.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.site.pages.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name:'SiteConfiguratorsList',  params: { id: site.id }
        }">
            <v-list-item-icon>
              <v-icon>mdi-washing-machine</v-icon>
            </v-list-item-icon>
            <v-list-item-title >{{ $vuetify.lang.t('$vuetify.site.configurators.name') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>

    <!--  Administration  -->
    <v-subheader>
      {{ _.toUpper($vuetify.lang.t('$vuetify.administration.name')) }}
    </v-subheader>
    <v-list rounded
            dense>
      <v-list-item-group
        active-class="light-blue--text text--accent-4">
        <v-list-item :to="{
        name:'AdminUsers'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $vuetify.lang.t('$vuetify.administration.users.name') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{
        name:'AdminVat'
        }">
          <v-list-item-icon>
            <v-icon>mdi-currency-eur</v-icon>
          </v-list-item-icon>
          <v-list-item-title>TVA</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{
        name:'AdminSetting'
        }">
          <v-list-item-icon>
            <v-icon>mdi-tune-vertical</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Variables d'environnement</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
// Utilities
import { sync } from 'vuex-pathify'

export default {
  name: 'DefaultDrawer',
  data () {
    return {
      sites: {}
    }
  },
  mounted () {
    this.fetchSites()
  },
  methods: {
    async fetchSites () {
      try {
        const response = await this.axios.get('/site/list', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        this.sites = response.data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.list.notification.load'))
      }
    }
  },
  computed: { drawer: sync('app/drawer') }
}
</script>
